<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
      <!-- form -->
        <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col :md="formData.type.value === 'event' ? '4' : '6'">
              <b-form-group
                  label="Cím"
              >
                <validation-provider
                    #default="{ errors }"
                    name="cím"
                    rules="required"
                    vid="title"
                >
                  <b-form-input
                      v-model="formData.title"
                      :state="errors.length > 0 ? false:null"
                      placeholder="cím"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="formData.type.value !== 'event'" md="6">
              <b-form-group
                  label="Dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="dátum"
                    rules=""
                    vid="date"
                >
                  <flat-pickr
                      v-model="formData.date"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="formData.type.value === 'event'" md="4">
              <b-form-group
                  label="Kezdő dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kezdő dátum"
                    rules=""
                    vid="start_date"
                >
                  <flat-pickr
                      v-model="formData.start_date"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="formData.type.value === 'event'" md="4">
              <b-form-group
                  label="Befejező dátum"
              >
                <validation-provider
                    #default="{ errors }"
                    name="befejező dátum"
                    rules=""
                    vid="end_date"
                >
                  <flat-pickr
                      v-model="formData.end_date"
                      class="form-control"
                      :config="flatPickRConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Típus"
              >
                <validation-provider
                    #default="{ errors }"
                    name="típus"
                    rules="required"
                    vid="type"
                >
                  <v-select
                      v-model="formData.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="typeOptions"
                      @input="typeChanged"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label="Státusz"
              >
                <validation-provider
                    #default="{ errors }"
                    name="státusz"
                    rules="required"
                    vid="status"
                >
                  <v-select
                      v-model="formData.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="statusOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Címke"
              >
                <validation-provider
                    #default="{ errors }"
                    name="címke"
                    vid="tags"
                >
                  <v-select
                      v-model="formData.tags"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      multiple
                      taggable
                      push-tags
                      :options="tagOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Szöveg"
              >
                <validation-provider
                    #default="{ errors }"
                    name="szöveg"
                    rules="required"
                    vid="content"
                >
                  <editor
                      v-model="formData.content"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <create-newsletter-from-entity-button class="float-right mr-2" variant="info" entity-type="document-library" :entity-id="formData.id" />
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      </b-overlay>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
          ref="tabs"
          @refresh-tabs-data="refreshTabsData"
          :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BFormDatepicker, BInputGroup, BInputGroupAppend, BLink, BOverlay,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import editor from '@/views/pages/_components/editor.vue'
import tabs from '@/views/pages/_components/_tabs/_documentLibrary/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import CreateNewsletterFromEntityButton from "@/views/pages/newsletter/component/CreateNewsletterFromEntityButton";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    flatPickr,
    BLink,
    BOverlay,
    vSelect,
    editor,
    tabs,
    cancelAndGOTOPreviousPage,
    CreateNewsletterFromEntityButton,
  },
  data() {
    return {
      role: 'document_library',
      tabsLoading: false,
      formData: {
        id: null,
        title: '',
        tags: [],
        type: '',
        content: '',
        date: '',
        start_date: '',
        end_date: '',
        status: '',
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      tagOptions: [],
      statusOptions: [],
      typeOptions: [],
      tabsProperties: {
        images: [],
        files: [],
      },
      loading: false,
      required,
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
      this.tagOptions = response
    })

    this.initDocumentLibrary()
  },
  methods: {
    initDocumentLibrary(onlyTabs = false) {
      this.$store.dispatch('fetchDocumentLibrary', this.$route.params.id).then(response => {
        this.processDocumentLibrary(response.data.entity, onlyTabs)
      })
    },
    processDocumentLibrary(documentLibrary, onlyTabs = false) {
      this.tabsLoading = true;
      this.loading = true

      if (!onlyTabs) {
        this.formData = documentLibrary

        let selectedTags = []

        // set tags
        this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
          this.tagOptions = response

          if (this.tagOptions.length && documentLibrary.tags && documentLibrary.tags.length) {
            Object.keys(documentLibrary.tags).forEach(tkey => {
              Object.keys(this.tagOptions).forEach(tokey => {
                if (documentLibrary.tags[tkey].id === this.tagOptions[tokey].value) {
                  selectedTags.push(this.tagOptions[tokey])
                }
              })
            })
          }

          this.formData.tags = selectedTags
        })

        this.$store.dispatch('documentLibraryStatuses').then(sResponse => {
          this.statusOptions = sResponse.data.values

          if (this.statusOptions.length) {
            Object.keys(this.statusOptions).forEach(key => {
              if (this.statusOptions[key].value === documentLibrary.status) {
                this.formData.status = this.statusOptions[key]
              }
            })
          }
        })

        this.$store.dispatch('documentLibraryTypes').then(tResponse => {
          this.typeOptions = tResponse.data.values

          if (this.typeOptions.length) {
            Object.keys(this.typeOptions).forEach(key => {
              if (this.typeOptions[key].value === documentLibrary.type) {
                this.formData.type = this.typeOptions[key]
              }
            })
          }
        })
      }

      this.tabsLoading = false;
      this.loading = false;
    },
    refreshTabsData() {
      this.$refs.tabs.refreshImages()
      this.$refs.tabs.refreshFiles()
      this.$refs.tabs.refreshLog()
    },
    typeChanged(){
      if(this.formData.type.value === 'event'){
        this.formData.date = ''
      }else{
        this.formData.start_date = ''
        this.formData.end_date = ''
      }
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          let fd = { ... this.formData}
          fd.status = this.formData.status.value
          fd.type = this.formData.type.value

          this.$store.dispatch('updateDocumentLibrary', fd).then(async response => {
            this.processDocumentLibrary(response.data.entity)
            this.$helpers.showSuccessToast()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
