var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Szerkesztés"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cím"}},[_c('validation-provider',{attrs:{"name":"cím","rules":"required","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"cím"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Státusz"}},[_c('validation-provider',{attrs:{"name":"státusz","rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.statusOptions},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Címke"}},[_c('validation-provider',{attrs:{"name":"címke","vid":"tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","taggable":"","push-tags":"","options":_vm.tagOptions},model:{value:(_vm.formData.tags),callback:function ($$v) {_vm.$set(_vm.formData, "tags", $$v)},expression:"formData.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Lead"}},[_c('validation-provider',{attrs:{"name":"lead","rules":"required","vid":"lead"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"10"},model:{value:(_vm.formData.lead),callback:function ($$v) {_vm.$set(_vm.formData, "lead", $$v)},expression:"formData.lead"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Szöveg"}},[_c('validation-provider',{attrs:{"name":"szöveg","rules":"required","vid":"article"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-link',{on:{"click":function($event){return _vm.$bvModal.show('bv-modal-article-help')}}},[_c('small',[_vm._v("* \"Speciális jelölések\" súgó")])]),_c('b-form-textarea',{attrs:{"rows":"20"},model:{value:(_vm.formData.article),callback:function ($$v) {_vm.$set(_vm.formData, "article", $$v)},expression:"formData.article"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Megjelenés kezdete"}},[_c('validation-provider',{attrs:{"name":"megjelenés kezdete","rules":"","vid":"visible_from"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.visible_from),callback:function ($$v) {_vm.$set(_vm.formData, "visible_from", $$v)},expression:"formData.visible_from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Megjelenés vége"}},[_c('validation-provider',{attrs:{"name":"megjelenés vége","rules":"","vid":"visible_to"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatPickRConfig},model:{value:(_vm.formData.visible_to),callback:function ($$v) {_vm.$set(_vm.formData, "visible_to", $$v)},expression:"formData.visible_to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"rögzített","vid":"sticky"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.sticky),callback:function ($$v) {_vm.$set(_vm.formData, "sticky", $$v)},expression:"formData.sticky"}},[_vm._v(" Rögzített írás? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.previewArticle(_vm.formData.slug, _vm.formData.id)}}},[_vm._v(" Előnézet ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1),_c('help-modal')],1),_c('b-overlay',{attrs:{"show":_vm.tabsLoading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"attributes":this.tabsProperties,"entity-id":parseInt(this.$route.params.id)},on:{"refresh-tabs-data":_vm.refreshTabsData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }