<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
    <!-- form -->
      <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col xs="12" md="4">
            <b-form-group
                label="Név"
            >
              <validation-provider
                  #default="{ errors }"
                  name="név"
                  rules="required"
                  vid="name"
              >
                <b-form-input
                    v-model="formData.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="név"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xs="12" md="4">
            <b-form-group
                label="Frissítési gyakoriság"
            >
              <validation-provider
                  #default="{ errors }"
                  name="frissítési gyakoriság"
                  rules="required"
                  vid="updateFrequency"
              >
                <b-form-input
                    v-model="formData.updateFrequency"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    placeholder="frissítési gyakoriság"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xs="12" md="4">
            <b-form-group
                label="Aktív"
            >
              <validation-provider
                  #default="{ errors }"
                  name="aktív"
                  rules="required"
                  vid="active"
              >
                <v-select
                    v-model="formData.active"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="activeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12">
            <b-form-group
                label="URL"
            >
              <validation-provider
                  #default="{ errors }"
                  name="url"
                  rules="required"
                  vid="url"
              >
                <b-form-input
                    v-model="formData.url"
                    :state="errors.length > 0 ? false:null"
                    placeholder="url"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" class="mt-2 mb-3">
            <b-button
                v-if="this.parameters.length < this.parameterOptions.length"
                variant="info"
                class="float-right"
                @click.prevent="addNewParameter"
            >
              Új paraméter
            </b-button>
            <span>Paraméterek:</span><br/>
            <span class="font-small-1 font-weight-bold">* Azonosító - amit visszakapsz a végponttól; Típus - amivel szeretnéd megfeleltetni a helyi rendszerben</span>
          </b-col>
        </b-row>
        <b-row
          v-for="parameter in parameters"
          :key="parameter.id"
        >
          <!-- paraméterek -->
          <b-col xs="12" md="6">
            <b-form-group
                label="Azonosító"
            >
              <validation-provider
                  #default="{ errors }"
                  name="azonosito"
                  rules="required"
                  vid="value"
              >
                <b-form-input
                    v-model="parameter.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="azonosító"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xs="12" md="5">
            <b-form-group
                label="Típus"
            >
              <validation-provider
                  #default="{ errors }"
                  name="típus"
                  rules="required"
                  vid="type"
              >
                <v-select
                    v-model="parameter.value"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="parameterOptions"
                    :selectable="(option) => option.selectable"
                    @input="parameterSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
              v-if="parameters.length > 1"
              xs="12"
              md="1"
          >
            <b-button
                variant="danger"
                size="sm"
                class="float-right mt-2"
                @click.prevent="removeParameter(parameter.id)"
            >
              <feather-icon
                  icon="Trash2Icon"
              />
            </b-button>
          </b-col>

        </b-row>
        <b-row class="mt-5">
          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BOverlay,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'meteorology',
      formData: {
        name: '',
        active: '',
        updateFrequency: 1,
        url: '',
        parameters: []
      },
      parameterId: 1,
      parameterSkeleton: { id: 0, name: '', value: '' },
      parameterOptions: [],
      activeOptions: [],
      parameters: [],
      loading: false,
      required,
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchMeteorologyDataSourceDataTypes').then(response => {
      this.parameterOptions = response.data.options
      // init params
      this.addNewParameter()
      this.setSelectableOptions()
    }).finally(() => {
      this.loading = false
    })

    this.activeOptions = this.$helpers.getBasicYesNoForVueSelect()
    this.formData.active = this.activeOptions[1]
  },
  methods: {
    setSelectableOptions() {
      let option = {}
      let options = []
      if (this.parameterOptions.length) {
        let selectable = true
        this.parameterOptions.forEach(parameterOption => {
          selectable = true

          if (this.parameters.length) {
            this.parameters.forEach(parameter => {
              if (parameter.value.value === parameterOption.value) {
                selectable = false
              }
            })
          }

          option = this.$helpers.cloneObject(parameterOption)
          option.selectable = selectable
          options.push(option)
        })
      }

      this.parameterOptions = options
    },
    parameterSelected() {
      this.setSelectableOptions()
    },
    addNewParameter() {
      let sk = this.$helpers.cloneObject(this.parameterSkeleton)
      sk.id = this.parameterId++
      this.parameters.push(sk)
    },
    removeParameter(id) {
      let parameters = []

      if (this.parameters.length) {
        this.parameters.forEach(parameter => {
          if (parameter.id !== id) {
            parameters.push(this.$helpers.cloneObject(parameter))
          }
        })
      }

      this.parameters = parameters
      this.setSelectableOptions()
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          let fd = this.$helpers.cloneObject(this.formData)
          fd.active = fd.active.value

          if (this.parameters.length) {
            this.parameters.forEach(parameter => {
              fd.parameters.push({ key: parameter.name, value: parameter.value.value })
            })
          }

          this.$store.dispatch('storeMeteorologyDataSource', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({ name: 'list-meteorology-datasource' })
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
