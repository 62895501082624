<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
      <!-- form -->
        <validation-observer ref="simpleRules">
        <b-form>
          <b-row>

            <b-col md="12">
              <b-form-group
                  label="Tárgy"
              >
                <validation-provider
                    #default="{ errors }"
                    name="tárgy"
                    rules="required"
                    vid="subject"
                >
                  <b-form-input
                      v-model="formData.subject"
                      :state="errors.length > 0 ? false:null"
                      placeholder="tárgy"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Szöveg"
              >
                <validation-provider
                    #default="{ errors }"
                    name="szöveg"
                    vid="body"
                >
                  <editor
                      v-model="formData.body"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <b-button
                  variant="warning"
                  type="submit"
                  class="float-right mr-2"
                  :disabled="!formData.previewed && !formData.testSent"
                  @click.prevent="sendNewsLetter()"
              >
                Kiküldés
              </b-button>
              <b-button
                  variant="info"
                  type="button"
                  class="float-right mr-2"
                  @click.prevent="testSend()"
              >
                Próbaküldés
              </b-button>
              <b-button
                  variant="secondary"
                  type="submit"
                  class="float-right mr-2"
                  @click.prevent="previewNewsletter(formData.slug, formData.id)"
              >
                Előnézet
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      </b-overlay>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
    <test-send ref="test-send" :id="parseInt(this.$route.params.id)" @test-sent="initEntity"></test-send>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BOverlay,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import tabs from '@/views/pages/_components/_tabs/_newsletter/update.vue'
import vSelect from 'vue-select'
import editor from '@/views/pages/_components/editor.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import testSend from "@/views/pages/_components/_modal/newsletter/testSend";

export default {
  components: {
    BOverlay,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    editor,
    cancelAndGOTOPreviousPage,
    testSend,
    tabs,
  },
  data() {
    return {
      role: 'newsletter',
      formData: {
        id: null,
        subject: '',
        slug: '',
        body: '',
        status: '',
        previewed: false,
        testSent: false,
      },
      baseUrl: process.env.VUE_APP_SERVER_BASE_URL,
      loading: false,
      required,
      email,
    }
  },
  created() {
    this.initEntity()
  },
  methods: {
    initEntity() {
      this.loading = true
      if(this.$refs.simpleRules) {
        this.$refs.simpleRules.reset();
      }
      this.$store.dispatch('fetchNewsletter', this.$route.params.id).then(response => {
        this.formData.id = this.$route.params.id
        this.formData.subject = response.data.entity.subject
        this.formData.slug = response.data.entity.slug
        this.formData.body = response.data.entity.body
        this.formData.status = response.data.entity.status
        this.formData.testSent = response.data.entity.test_sent
        this.formData.previewed = response.data.entity.previewed
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm(redirect = true) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateNewsletter', fd).then(() => {
            this.$refs.tabs.refreshLog()
            if (redirect) {
              this.$helpers.showSuccessToast()
              this.$acl.canListPermission(this.role)
                  ? this.$router.push({name: 'newsletters'})
                  : this.$router.go(-1)
            }
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    previewNewsletter(slug, id) {
      window.setTimeout(() => {
        this.initEntity()
      }, 1000)
      window.open(this.baseUrl + '/hirlevelek/hirlevel/' + slug + '-h' + id, '_blank');
    },
    async testSend(){
      await this.submitForm(false);
      this.$refs["test-send"].$refs['form-newsletter-test-send-modal'].show()
    },
    sendNewsLetter() {
      this.$bvModal
          .msgBoxConfirm('Biztosan kiküldöd a hírlevelet?', {
            title: '',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Küldés',
            cancelTitle: 'Mégsem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(async value => {
            if (value) {

              await this.submitForm(false)

              let fd = JSON.parse(JSON.stringify(this.formData))

              this.loading = true

              this.$store.dispatch('newsletterSend', fd).then(() => {
                this.$helpers.showSuccessToast('A hírlevél kiküldése elkezdődött.')
                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'newsletters'})
                    : this.$router.go(-1)
                this.$refs.simpleRules.reset()
              }).finally(() => {
                this.loading = false
              })
            }
          })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
