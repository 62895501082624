var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Szerkesztés"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tárgy"}},[_c('validation-provider',{attrs:{"name":"tárgy","rules":"required","vid":"subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Tárgy"},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('variables',{attrs:{"variables":_vm.formData.variables}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Üdvözlés"}},[_c('validation-provider',{attrs:{"name":"Üdvözlés","rules":"required","vid":"greeting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Üdvözlés"},model:{value:(_vm.formData.greeting),callback:function ($$v) {_vm.$set(_vm.formData, "greeting", $$v)},expression:"formData.greeting"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('variables',{attrs:{"variables":_vm.formData.variables}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tartalom"}},[_c('validation-provider',{attrs:{"name":"tartalom","rules":"required","vid":"content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('variables',{attrs:{"variables":_vm.formData.variables}})]}}])})],1)],1),(_vm.formData.has_action)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Gombfelirat"}},[_c('validation-provider',{attrs:{"name":"Gombfelirat","rules":"required","vid":"action_text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Gombfelirat"},model:{value:(_vm.formData.action_text),callback:function ($$v) {_vm.$set(_vm.formData, "action_text", $$v)},expression:"formData.action_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2537065761)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1),_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"entity-id":parseInt(this.$route.params.id)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }