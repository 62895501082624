var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Szerkesztés"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Név"}},[_c('validation-provider',{attrs:{"name":"cím","rules":"required","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"cím"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Címke"}},[_c('validation-provider',{attrs:{"name":"címke","vid":"tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","multiple":"","taggable":"","push-tags":"","options":_vm.tagOptions},model:{value:(_vm.formData.tags),callback:function ($$v) {_vm.$set(_vm.formData, "tags", $$v)},expression:"formData.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"mező","vid":"is_public"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.is_public),callback:function ($$v) {_vm.$set(_vm.formData, "is_public", $$v)},expression:"formData.is_public"}},[_vm._v(" Nyilvános? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"mező","vid":"is_approved"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.is_approved),callback:function ($$v) {_vm.$set(_vm.formData, "is_approved", $$v)},expression:"formData.is_approved"}},[_vm._v(" Publikált? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"mező","vid":"show_in_home_page"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.show_in_home_page),callback:function ($$v) {_vm.$set(_vm.formData, "show_in_home_page", $$v)},expression:"formData.show_in_home_page"}},[_vm._v(" Megjelenik a főoldalon? ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-left",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.downloadGallery.apply(null, arguments)}}},[_vm._v(" Galéria letöltése ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.tabsLoading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"entity-id":parseInt(this.$route.params.id)},on:{"refresh-tabs-data":_vm.refreshTabsData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }