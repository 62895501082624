var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Új hozzáadása"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Tárgy"}},[_c('validation-provider',{attrs:{"name":"tárgy","rules":_vm.subjectRequired ? 'required' : '',"vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"tárgy"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Címzett"}},[_c('validation-provider',{attrs:{"name":"címzett","rules":"required","vid":"userId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.userOptions},model:{value:(_vm.formData.userId),callback:function ($$v) {_vm.$set(_vm.formData, "userId", $$v)},expression:"formData.userId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Üzenet típusa"}},[_c('validation-provider',{attrs:{"name":"üzenet típus","rules":"","vid":"modelType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.modelTypeOptions,"selectable":function (option) { return !option.disabled; }},on:{"input":_vm.onChangeEntityType},model:{value:(_vm.formData.modelType),callback:function ($$v) {_vm.$set(_vm.formData, "modelType", $$v)},expression:"formData.modelType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Példány"}},[_c('validation-provider',{attrs:{"name":"példány","rules":_vm.modelIdRequired ? 'required' : '',"vid":"modelId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.modelOptionsForModelType},model:{value:(_vm.formData.modelId),callback:function ($$v) {_vm.$set(_vm.formData, "modelId", $$v)},expression:"formData.modelId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Üzenet"}},[_c('validation-provider',{attrs:{"name":"üzenet","rules":"required","vid":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('editor',{model:{value:(_vm.formData.message),callback:function ($$v) {_vm.$set(_vm.formData, "message", $$v)},expression:"formData.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" Mentés ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }