<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
      <!-- form -->
        <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  label="Tárgy"
              >
                <validation-provider
                    #default="{ errors }"
                    name="tárgy"
                    rules="required"
                    vid="subject"
                >
                  <b-form-input
                      v-model="formData.subject"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Tárgy"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <variables :variables="formData.variables"/>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Üdvözlés"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Üdvözlés"
                    rules="required"
                    vid="greeting"
                >
                  <b-form-input
                      v-model="formData.greeting"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Üdvözlés"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <variables :variables="formData.variables"/>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label="Tartalom"
              >
                <validation-provider
                    #default="{ errors }"
                    name="tartalom"
                    rules="required"
                    vid="content"
                >
                  <editor
                      v-model="formData.content"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <variables :variables="formData.variables"/>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="formData.has_action" md="12">
              <b-form-group
                  label="Gombfelirat"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Gombfelirat"
                    rules="required"
                    vid="action_text"
                >
                  <b-form-input
                      v-model="formData.action_text"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Gombfelirat"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                Mentés
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      </b-overlay>
    </b-card-code>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_emailTemplate/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import variables from '@/views/pages/_components/_helper/variables.vue'
import editor from '@/views/pages/_components/editor.vue'

export default {
  components: {
    BOverlay,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    tabs,
    editor,
    cancelAndGOTOPreviousPage,
    variables
  },
  data() {
    return {
      role: 'text_template',
      formData: {
        id: null,
        title: '',
        subject: '',
        greeting: '',
        content: '',
        description: '',
        variables: '',
        has_action: false,
        action_text: ''
      },
      loading: false,
      required,
      email,
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchEmailTemplate', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.formData.id = this.$route.params.id
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateEmailTemplate', fd).then(() => {
            this.$refs.tabs.refreshLog()
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({ name: 'email-templates' })
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
