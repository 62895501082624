<template>
  <div>
    <b-card-code
        title="Szerkesztés"
    >
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group
                    label="Cím"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="cím"
                      rules="required"
                      vid="title"
                  >
                    <b-form-input
                        v-model="formData.title"
                        :state="errors.length > 0 ? false:null"
                        placeholder="cím"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Státusz"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="státusz"
                      rules="required"
                      vid="status"
                  >
                    <v-select
                        v-model="formData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="statusOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    label="Címke"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="címke"
                      vid="tags"
                  >
                    <v-select
                        v-model="formData.tags"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        multiple
                        taggable
                        push-tags
                        :options="tagOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    label="Lead"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="lead"
                      rules="required"
                      vid="lead"
                  >
                    <b-form-textarea
                        v-model="formData.lead"
                        rows="10"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    label="Szöveg"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="szöveg"
                      rules="required"
                      vid="article"
                  >
                    <b-link
                        @click="$bvModal.show('bv-modal-article-help')"
                    >
                      <small>* "Speciális jelölések" súgó</small>
                    </b-link>
                    <b-form-textarea
                        v-model="formData.article"
                        rows="20"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Megjelenés kezdete"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="megjelenés kezdete"
                      rules=""
                      vid="visible_from"
                  >
                    <flat-pickr
                        v-model="formData.visible_from"
                        class="form-control"
                        :config="flatPickRConfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Megjelenés vége"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="megjelenés vége"
                      rules=""
                      vid="visible_to"
                  >
                    <flat-pickr
                        v-model="formData.visible_to"
                        class="form-control"
                        :config="flatPickRConfig"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <validation-provider
                    #default="{ errors }"
                    name="rögzített"
                    vid="sticky"
                >
                  <b-form-checkbox
                      v-model="formData.sticky"
                      :state="errors.length > 0 ? false:null"
                  >
                    Rögzített írás?
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  Mentés
                </b-button>
                <b-button
                    variant="secondary"
                    type="submit"
                    class="float-right mr-2"
                    @click.prevent="previewArticle(formData.slug, formData.id)"
                >
                  Előnézet
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
      <help-modal/>
    </b-card-code>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            @refresh-tabs-data="refreshTabsData"
            :attributes="this.tabsProperties"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import labels from "@/libs/datepicker/labels";
import helpModal from '@/views/pages/_components/_modal/article/help.vue'
import tabs from '@/views/pages/_components/_tabs/_article/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BOverlay,
    BFormTextarea,
    flatPickr,
    vSelect,
    helpModal,
    tabs,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'article',
      tabsLoading: false,
      baseUrl: process.env.VUE_APP_SERVER_BASE_URL,
      formData: {
        id: null,
        title: '',
        tags: [],
        lead: '',
        article: '',
        visible_from: '',
        visible_to: '',
        status: '',
        sticky: '',
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      tagOptions: [],
      statusOptions: [],
      tabsProperties: {
        images: [],
        videos: [],
        files: [],
        relatedArticles: [],
      },
      loading: false,
      labels,
      required,
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
      this.tagOptions = response
    })

    this.initArticle()
  },
  methods: {
    initArticle(onlyTabs = false) {
      this.$store.dispatch('fetchArticle', this.$route.params.id).then(response => {
        this.processArticle(response.data.entity, onlyTabs)
      })
    },
    processArticle(article, onlyTabs = false) {
      this.tabsLoading = true;
      this.loading = true;

      if (!onlyTabs) {
        this.formData = article

        let selectedTags = []

        // set tags
        this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
          this.tagOptions = response

          if (this.tagOptions.length && article.tags && article.tags.length) {
            Object.keys(article.tags).forEach(tkey => {
              Object.keys(this.tagOptions).forEach(tokey => {
                if (article.tags[tkey].id === this.tagOptions[tokey].value) {
                  selectedTags.push(this.tagOptions[tokey])
                }
              })
            })
          }

          this.formData.tags = selectedTags
        })

        this.$store.dispatch('articleStatuses').then(sResponse => {
          this.statusOptions = sResponse.data.values

          if (this.statusOptions.length) {
            Object.keys(this.statusOptions).forEach(key => {
              if (this.statusOptions[key].value === article.status) {
                this.formData.status = this.statusOptions[key]
              }
            })
          }
        })
      }

      // set tabs data
      this.tabsProperties = {
        images: [],
        videos: [],
        files: [],
        relatedArticles: [],
      }

      if (article.parsed.paragraphs.length) {
        let tmp = []
        Object.keys(article.parsed.paragraphs).forEach(key => {
          tmp = [article.parsed.paragraphs[key]['data']]

          if (article.parsed.paragraphs[key]['type'] === 'image') {
            this.tabsProperties.images = [...this.tabsProperties.images, ...tmp]
          }

          // if (article.parsed.paragraphs[key]['type'] === 'file') {
          //   this.tabsProperties.files = [...this.tabsProperties.files, ...tmp]
          // }

          if (article.parsed.paragraphs[key]['type'] === 'video') {
            this.tabsProperties.videos = [...this.tabsProperties.videos, ...tmp]
          }
        })
      }

      // fileok kezelése
      if (article.parsed.files.length) {
        let tmp = []
        Object.keys(article.parsed.files).forEach(key => {
          tmp = [article.parsed.files[key]['data']]
          this.tabsProperties.files = [...this.tabsProperties.files, ...tmp]
        })
      }

      if (article.parsed.relatedArticles.length) {
        this.tabsProperties.relatedArticles = article.parsed.relatedArticles
      }

      this.tabsLoading = false;
      this.loading = false;
    },
    refreshTabsData() {
      this.initArticle(true)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true;
          let fd = {...this.formData}
          fd.status = this.formData.status.value

          this.$store.dispatch('updateArticle', fd).then(async response => {
            this.processArticle(response.data.entity)
            this.$helpers.showSuccessToast()
            this.$refs.tabs.refreshLog()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    previewArticle(slug, id) {
      window.setTimeout(() => {
        this.initEntity()
      }, 1000)
      window.open(this.baseUrl + '/iras/' + slug + '-a' + id, '_blank');
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
